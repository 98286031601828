import * as React from 'react';
import PageWrapper from '../components/pagewrapper/pageWrapper';
import { Grid } from 'semantic-ui-react';
import { Link } from 'gatsby';
import { DiscussionEmbed } from 'disqus-react';

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  const disqusConfig = {
    shortname: 'kevinjxu',
    config: { identifier: `${frontmatter.date} ${frontmatter.title}` },
  };
  return (
    <PageWrapper
      activeItem={'blog-post'}
      blogTitle={frontmatter.title}
      blogDescription={frontmatter.description}
      blogPath={frontmatter.path}
    >
      <Grid centered={true}>
        <Grid.Column
          mobile={12}
          tablet={10}
          computer={8}
          largeScreen={8}
          widescreen={8}
          className={'page-wrapper-container'}
        >
          <div className={'back-button'}>
            <Link to={'/blog'}>
              <h2>Back</h2>
            </Link>
          </div>
          <div className="blog-post-container">
            <div className="blog-post">
              <h1 className={'blog-post-title'}>{frontmatter.title}</h1>
              <h2 className={'blog-post-date'}>{frontmatter.date}</h2>
              <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <DiscussionEmbed {...disqusConfig} />
          </div>
        </Grid.Column>
      </Grid>
    </PageWrapper>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
      }
    }
  }
`;
